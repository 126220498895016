/* @font-face {
    font-family: 'ClashDisplay';
    src: url('../public/fonts/ClashDisplay-Variable.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  body {
    font-family: 'ClashDisplay', sans-serif;
  }
  
  
  .App {
    text-align: center;
  }
  .app-container{
    background-color: #0F0F0F;
    padding: 20px;
  } */
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .bento-card-icon{
    width: 60px;
    height: 60px;
  }
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }

  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .container {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* padding: 20px; */
    gap: 20px;
}

.card {
    height: 100%;
    background-color: #0F0F0F;
    border: 1px solid #787878;
    border-radius: 16px;
    overflow: hidden;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    width: 300px;
    margin: 10px;
    padding: 20px;
    text-align: center;
    color: #fff;
    cursor: pointer;
    
}
.card:hover{
  background-color: #181818;
  border: 1px solid #3e0067;
}

.card img {
    width: 60px;
    height: auto;
    border-radius: 50%;
    margin-bottom: 10px;
}

.card h3 {
    margin: 10px 0;
    font-size: 1.2rem;
}

.card p {
    margin: 0 0 15px;
    font-size: 1rem;
    color: #bbb;
}

@media (min-width: 768px) {
    .container {
        flex-direction: row;
        /* flex-wrap: wrap; */
    }
}

.home-container{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.hero-container-section{
  width: 100%;
}
.herocardsection{
  transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
  opacity: 0;
  visibility: hidden;
  width: 100%;
  /* display: flex; */
}
.herocardsection.visible {
  opacity: 1;
  visibility: visible;
}
.card-content{
  display: flex;
  flex-direction: column;
  align-items: center;
}