/* .about-me-page-container{
    
 
      

} */

.particles-container {
    position: absolute;
    
    z-index: 0; /* Ensure particles are behind the container */
    
  }
  .about-me-card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 800px;
  }
  @media (min-width: 768px) {
    .about-me-card-content {
      flex-direction: row;
    }
  }
  
  
  .text-content {
    flex: 1 1;
    display: flex;
    height: 300px;
    gap: 30px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: nowrap;
  }
  .text-content-container{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .image-content {
    flex: 1;
    display: flex;
    justify-content: center;
  }
  
  .company-logo {
    max-width: 100%;
    height: auto;
  }
  
  .cta-button {
    background-color: #6b46c1; /* Change this color as per your theme */
    border: none;
    padding: 10px 20px;
    color: white;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .cta-button:hover {
    background-color: #553c9a; /* Change this color as per your theme */
  }

  
  