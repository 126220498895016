.profile {
    display: flex;
    align-items: center;
    background-color: #2D2D2D;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .profile img {
    width: 105px;
    height: 105px;
    border-radius: 10px;
    margin-right: 16px;
  }
  
  .profile h1 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px;
  }
  
  .profile h2 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 8px;
  }
  
  .profile h3 {
    font-size: 16px;
    margin-bottom: 16px;
  }
  