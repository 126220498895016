@font-face {
  font-family: 'ClashDisplay';
  src: url('../public/fonts/ClashDisplay-Variable.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'ClashDisplay', sans-serif;
}

/* index.css or App.css */
html, body, #root {
  height: 100vh;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden; /* Prevents scrolling on the root elements */
}

*, *::before, *::after {
  box-sizing: inherit;
}
#root {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.app-container {
  background-color: #0F0F0F;
  padding: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow-y: auto; /* Enables vertical scrolling if content exceeds viewport height */
  box-sizing: border-box;
}

.App {
  text-align: center;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.bento-card-icon{
  width: 60px;
  height: 60px;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.navbar-container{
  display: flex;
  align-items: center;
  background-color: transparent;
  justify-content: center;
  padding: 1px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
