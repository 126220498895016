/* InitialScreen.css */

.quote-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    opacity: 1;
    transition: opacity 3s ease-in-out; /* 3-second fade-out transition */
  }
  
  .quote-overlay.fade-out {
    opacity: 0;
  }
  
  .quote-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    max-width: 600px;
    width: 100%;
    height: 80%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  }
  
  
  