.hero-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem;
    background: rgba(255, 255, 255, 0.1); /* Slightly transparent background */
    border-radius: 18px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    position: relative;
    backdrop-filter: blur(10px); /* Blurring effect */
    border: 1px solid rgba(255, 255, 255, 0.3); /* Border with transparency */
    height: 100%;
}
  
  .hero-card-content {
    flex: 1;
    color: #ffffff;
    margin-right: 1rem;
  }
  
  .hero-card-content h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: #ffffff;
    font-family: 'ClashDisplay', sans-serif;
    font-weight: bold;
  }
  
  .hero-card-content p {
    font-size: 1.25rem;
    color: #ccc;
    font-family: 'ClashDisplay', sans-serif;
    font-weight: 300;
  }
  
  .hero-card-image {
    flex: 1;
    display: flex;
    justify-content: center;
  }
  
  .hero-card-image img {
    width: 90%;
    height: 320px;
    border-radius: 15px;
    object-fit: cover;
  }
  
  
  .cta-button {
    margin-top: 1rem;
    padding: 0.75rem 1.5rem;
    background-color: rgba(108, 99, 255, 0.8); /* Slightly transparent button */
    color: #ffffff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-family: 'ClashDisplay', sans-serif;
    font-weight: bold;
    font-size: 1rem;
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
  }
  
  .cta-button:hover {
    background-color: rgba(90, 82, 224, 0.8);
    transform: scale(1.05);
  }
  
  @media (max-width: 768px) {
    .hero-card {
      flex-direction: column;
      text-align: center;
    }
    .hero-card-content {
      max-width: 100%;
      margin-bottom: 1rem;
    }
    .hero-card-image {
      display: none; /* Hide the image in mobile view */
    }
  }
  